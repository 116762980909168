import React from 'react';
interface ReviewProps extends React.HTMLAttributes<HTMLDivElement> {
  image: string;
  name: string;
  designation: string;
  company: string;
  headline: string;
  review: string;
}

const Review = ({
  image,
  name,
  designation,
  company,
  headline,
  review,
  className,
}: ReviewProps) => {
  return (
    <div
      className={`rounded-xl flex flex-col gap-5 p-4 md:p-6 bg-[#F9F9F9] transition duration-300 hover:bg-white hover:shadow-[0_12px_50px_rgba(124,124,124,_0.4)] ${className}`}
    >
      <div className="flex flex-col gap-3">
        <div className="text-xl">{headline}</div>
        <div className="italic text-blackSecondary opacity-75 pr-8">
          "{review}"
        </div>
      </div>
      <div className="flex items-center gap-3">
        <div className="rounded-full w-10 h-auto overflow-hidden">
          <img
            src={image}
            alt="Reviewer"
            className="rounded-full w-10 h-auto transform transition duration-300 hover:scale-[102%]"
          />
        </div>
        <div className="flex flex-col">
          <div className="text-blackSecondary opacity-75">{name}</div>
          <div className="flex gap-1">
            <div className="text-blackSecondary opacity-75">
              {designation}
              <span className="text-brand"> @{company}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
