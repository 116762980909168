import React from 'react';
interface ActivityProps extends React.HTMLAttributes<HTMLDivElement> {
  url: string;
  alt: string;
  title: string;
}

const Activity = ({ url, alt, title, className }: ActivityProps) => {
  return (
    <div className={`relative rounded-lg ${className}`}>
      <div className="rounded-2xl w-full h-auto overflow-hidden">
        <img
          src={url}
          alt={alt}
          className="rounded-2xl w-full h-auto transform transition duration-300 hover:scale-[102%]"
        />
      </div>
      <div className="absolute bottom-4 left-4 flex items-center bg-brandSecondary gap-2 p-1 px-2 rounded-md w-fit">
        <div className="text-sm text-brand font-medium">{title}</div>
      </div>
    </div>
  );
};

export default Activity;
