import React from 'react';
import Header from '../components/header';
import HeroSection from '../components/sections/heroSection';
import CompanySection from '../components/sections/companySection';
import PortfolioSection from '../components/sections/portfolioSection';
import ProfileSection from '../components/sections/profileSection';
import SectionsSection from '../components/sections/sectionsSection';
import ReviewsSection from '../components/sections/reviewsSection';
import Footer from '../components/sections/footer';

const HomePage: React.FC = () => {
  return (
    <div className="mx-4 sm:mx-16 md:mx-24 lg:mx-32 xl:mx-48">
      <Header />
      <HeroSection />
      <CompanySection />
      <PortfolioSection />
      <ProfileSection />
      <SectionsSection />
      <ReviewsSection />
      <Footer />
    </div>
  );
};

export default HomePage;
