import React from 'react';
import TimelineItem from '../timelineItem';

const AwardsSection = () => {
  return (
    <div className="flex flex-col gap-2 px-4">
      <TimelineItem
        title="Foundations of UX Design"
        subtitle="Google"
        dateFrom="2023"
        liItems={[
          'Mastered foundational concepts in UX design, such as user-centered design, the design process, accessibility, and equity-focused design.',
        ]}
        className="pb-6 border-b"
      />
      <TimelineItem
        title="Python Gold Medalist"
        subtitle="Hackerrank"
        dateFrom="2022"
        liItems={[
          'Mastered Python programming, including advanced concepts such as algorithm optimization, data structures, software development best practices, and machine learning applications.',
        ]}
        className="pb-6 border-b"
      />
      <TimelineItem
        title="3rd Best Industry-Adjudged Project"
        subtitle="NUST University"
        dateFrom="2022"
        liItems={[
          'Awarded with 3rd best adjudged project in Final Year Project: Pedant - where we built a data-driven deep learning model, trained enough to learn the voice properties of a person and create a deep voice with as low as 3 seconds audio from a corpus of 10 million speakers dataset.',
        ]}
        className="pb-6 border-b"
      />
      <TimelineItem
        title="1st Place Winner - Gold Medalist"
        subtitle="Helyx India Hacks (International)"
        dateFrom="2020"
        liItems={[
          'Participated in an online competition on Devpost and my project was awarded with The Best Overall as well as Popular Choice award. This also shortlisted me for winning a Gold Medal from my university, NUST.',
        ]}
        className="pb-2"
      />
    </div>
  );
};

export default AwardsSection;
