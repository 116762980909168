import { Pencil, Send, SlidersVertical, Users } from 'lucide-react';
import Feature from '../../feature';

const ProfileSection = () => {
  const features = [
    {
      icon: (
        <Users
          height={24}
          width={24}
          strokeWidth="2"
          className="text-brand opacity-80"
        />
      ),
      title: 'Stakeholder alignment',
      desc: 'Before starting a project, I conduct interviews and workshops to understand the mission of stakeholders.',
    },
    {
      icon: (
        <SlidersVertical
          height={24}
          width={24}
          strokeWidth="2"
          className="text-brand opacity-80"
        />
      ),
      title: 'Tailoring design processes',
      desc: 'No universal design process fits all contexts. I adapt my design process to fit the team and their context.',
    },
    {
      icon: (
        <Pencil
          height={24}
          width={24}
          strokeWidth="2"
          className="text-brand opacity-80"
        />
      ),
      title: 'Design research',
      desc: 'My design process begins with research and data analysis to gain insights into complex customer behaviors.',
    },
    {
      icon: (
        <Send
          height={24}
          width={24}
          strokeWidth="2"
          className="text-brand opacity-80"
        />
      ),
      title: 'Continuous learning',
      desc: 'I believe in continuous development through fast delivery and early interaction with customers.',
    },
  ];

  return (
    <div id="profileSection" className="w-full pt-16 pb-12">
      <div className="flex flex-col md:flex-row gap-4 md:gap-8">
        <div className="flex flex-col gap-4 md:w-3/12">
          <div className="text-sm text-brand font-medium">About me</div>
          <div className="flex flex-col gap-1">
            <div className="text-3xl font-medium">Saif Ullah Bin Khaki</div>
            <div className="text-2xl font-medium opacity-20">
              Product Designer
            </div>
          </div>
        </div>
        <div className="text-lg md:w-9/12">
          Born in Pakistan, I've studied and worked in diverse metropolises,
          including Islamabad, Dubai, New Mexico, and Berlin. Through my
          professional journey, I've partnered with several top brands such as
          Diamond Developers, ESGLibrary, Devigners, and more. I thrive on open
          and transparent collaboration, which in my opinion, a key ingredient
          in creating impactful digital products.
        </div>
      </div>
      <div className="flex flex-wrap gap-4 pt-12 md:pt-16">
        {features.map((feature, index) => (
          <Feature
            icon={feature.icon}
            title={feature.title}
            desc={feature.desc}
            className="w-full min-w-44 flex-1"
          />
        ))}
      </div>
    </div>
  );
};

export default ProfileSection;
