import React from 'react';
interface FeatureProps extends React.HTMLAttributes<HTMLDivElement> {
  icon: React.ReactNode;
  title: string;
  desc: String;
}

const Feature = ({ icon, title, desc, className }: FeatureProps) => {
  return (
    <div
      className={`rounded-lg bg-brandSecondary bg-opacity-50 flex flex-col gap-3 md:gap-4 p-4 ${className}`}
    >
      <div className="p-2 md:p-3 bg-white rounded-lg md:rounded-xl w-fit">
        {icon}
      </div>
      <div className="flex flex-col gap-1">
        <div className="text-lg font-medium text-brand">{title}</div>
        <div className="text-sm text-brand opacity-75">{desc}</div>
      </div>
    </div>
  );
};

export default Feature;
