import React from 'react';
import TimelineItem from '../timelineItem';

const SideInitiativesSection = () => {
  return (
    <div className="flex flex-col gap-2 px-4">
      <TimelineItem
        title="UI UX Community"
        subtitle="Organizer"
        dateFrom="2022"
        dateTo="2024"
        liItems={[
          'Organize monthly meetups, workshops, and webinars for a 1,000+ designers community from all over all world - connect, learn, and grow together with Figma',
        ]}
        className="pb-6 border-b"
      />
      <TimelineItem
        title="Graduate Software Engineer"
        subtitle="Cobblestone Energy"
        dateFrom="2022"
        liItems={[
          'Attended a one-week graduate trainee program at Cobblestone, which was fully funded by the company. The program included training on the European electricity market, including how trades occur and how traders and brokers earn a profit. The experience was highly informative, providing insight not only into the field but also in data analytics and its applications.',
        ]}
        className="pb-6 border-b"
      />
      <TimelineItem
        title="Alpha Student Ambassador"
        subtitle="Microsoft Learn Student Ambassadors"
        dateFrom="2020"
        dateTo="2022"
        liItems={[
          "Honored to serve as an Alpha Microsoft Student Learn Ambassador during my Bachelors degree. In this role, I got to share my love for technology and help others discover the power of Microsoft's learning resources. Whether it's through workshops, presentations, or online events, I have had the opportunity to connect with students and educators to provide guidance and inspire others to pursue their passions in technology.",
        ]}
        className="pb-2"
      />
    </div>
  );
};

export default SideInitiativesSection;
