import { useState } from 'react';

const PortfolioSection = () => {
  const [tabSelected, setTabSelected] = useState('Light');
  // const [isSticky, setIsSticky] = useState(false);

  // const portfolio_categories = ['Light', 'Dark', 'Logos', 'Applications'];
  const portfolio_categories = ['Light', 'Dark'];
  const portfolios = [
    {
      image: './portfolios/portfolio-1.webp',
      responsive_image: './portfolios/portfolio-mob-1.webp',
      alt: 'TSC-Yiti Portfolio',
      url: 'https://dribbble.com/shots/24491855-The-Sustainable-City-Yiti-Website-Design',
      categories: ['Light'],
    },
    {
      image: './portfolios/portfolio-2.webp',
      responsive_image: './portfolios/portfolio-mob-2.webp',
      alt: 'Hounter Portfolio',
      url: 'https://dribbble.com/shots/23510134-Hounter-A-Real-Estate-Website',
      categories: ['Light'],
    },
    {
      image: './portfolios/portfolio-3.webp',
      responsive_image: './portfolios/portfolio-mob-3.webp',
      alt: 'TSC Portfolio',
      url: 'https://dribbble.com/shots/24477618-The-Sustainable-City-A-Sustainable-Lifestyle',
      categories: ['Light'],
    },
    {
      image: './portfolios/portfolio-4.webp',
      responsive_image: './portfolios/portfolio-mob-4.webp',
      alt: 'Mercury Portfolio',
      url: 'https://dribbble.com/shots/24492771-Mercury-Website-Design',
      categories: ['Light'],
    },
    {
      image: './portfolios/portfolio-5.webp',
      responsive_image: './portfolios/portfolio-mob-5.webp',
      alt: 'Trapix Portfolio',
      url: 'https://dribbble.com/shots/23406130-Trapix-Buy-Sell-Crypto-In-Minutes-on-Trapix',
      categories: ['Dark'],
    },
    {
      image: './portfolios/portfolio-6.webp',
      responsive_image: './portfolios/portfolio-mob-6.webp',
      alt: 'Devigners Portfolio',
      url: 'https://dribbble.com/shots/23343418-Devigners-From-Concept-to-Canvas',
      categories: ['Dark'],
    },
    {
      image: './portfolios/portfolio-7.webp',
      responsive_image: './portfolios/portfolio-mob-7.webp',
      alt: 'Islam and Sustainability Portfolio',
      url: 'https://dribbble.com/shots/24476947-Islam-And-Sustainability-A-Sustainable-Website',
      categories: ['Dark'],
    },
    {
      image: './portfolios/portfolio-8.webp',
      responsive_image: './portfolios/portfolio-mob-8.webp',
      alt: 'ESGLibrary Portfolio',
      url: 'https://dribbble.com/shots/23340620-ESGLibrary-A-Sustainable-Website',
      categories: ['Dark'],
    },
  ];

  return (
    <div className="w-full pt-12 pb-16 border-b">
      <div className={`flex py-4 justify-between`}>
        <div className="flex flex-col gap-2">
          <div className="text-sm text-brand font-medium">Showcasing</div>
          <div className="text-xl font-medium">Portfolio Projects</div>
        </div>
        <div className="flex gap-4 md:gap-8 mb-1 pt-2 md:pt-8 items-end">
          {portfolio_categories.map((category, index) => (
            <div
              key={index}
              className={`relative text-sm h-fit font-medium text-blackSecondary hover:text-brand hover:opacity-100 brand-transition cursor-pointer ${
                tabSelected === category
                  ? 'text-brand opacity-100'
                  : 'opacity-50'
              }`}
              onClick={() => setTabSelected(category)}
            >
              {category}
              {tabSelected === category && (
                <div className="absolute -bottom-1 left-0 h-[1.5px] w-full bg-brand rounded-full"></div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-4 md:gap-10 lg:gap-12 pt-2 md:pt-4">
        {portfolios.map((portfolio, index) => {
          if (portfolio.categories.includes(tabSelected)) {
            return (
              <a
                className="w-full h-auto rounded-lg md:rounded-3xl overflow-hidden cursor-pointer"
                href={portfolio.url}
              >
                <img
                  key={index}
                  src={
                    window.innerWidth < 600
                      ? portfolio.responsive_image
                      : portfolio.image
                  }
                  alt={portfolio.alt}
                  className="w-full h-auto rounded-2xl md:rounded-3xl transform transition duration-300 hover:scale-[102%]"
                />
              </a>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default PortfolioSection;
