import Activity from './activity';

const ActivitiesSection = () => {
  const activities = [
    {
      url: './activities/activity-9.webp',
      title: 'Meetup @Employed.world',
      alt: 'Before starting a project, I conduct interviews and workshops to understand the mission of stakeholders.',
    },
    {
      url: './activities/activity-5.webp',
      title: 'UX Meetup @FOF Dubai',
      alt: 'Before starting a project, I conduct interviews and workshops to understand the mission of stakeholders.',
    },
    {
      url: './activities/activity-2.webp',
      title: 'Anniversary @Diamond Developers',
      alt: 'Before starting a project, I conduct interviews and workshops to understand the mission of stakeholders.',
    },
    {
      url: './activities/activity-3.webp',
      title: 'Onboarding @TSC Dubai',
      alt: 'Before starting a project, I conduct interviews and workshops to understand the mission of stakeholders.',
    },
    {
      url: './activities/activity-4.webp',
      title: 'UX Meetup @FOF Dubai',
      alt: 'Before starting a project, I conduct interviews and workshops to understand the mission of stakeholders.',
    },
    {
      url: './activities/activity-7.webp',
      title: 'Le Mans Series @Dubai Autodrome',
      alt: 'Before starting a project, I conduct interviews and workshops to understand the mission of stakeholders.',
    },
    {
      url: './activities/activity-6.webp',
      title: 'Team Meetup @Diamond Developers',
      alt: 'Before starting a project, I conduct interviews and workshops to understand the mission of stakeholders.',
    },
    {
      url: './activities/activity-1.webp',
      title: 'Joining @Diamond Developers',
      alt: 'Before starting a project, I conduct interviews and workshops to understand the mission of stakeholders.',
    },
    {
      url: './activities/activity-8.webp',
      title: 'GSE @Cobblestone Dubai',
      alt: 'Before starting a project, I conduct interviews and workshops to understand the mission of stakeholders.',
    },
  ];

  return (
    <div className="flex gap-4 pt-2 overflow-x-scroll px-4">
      {activities.map((activity, index) => (
        <Activity
          url={activity.url}
          alt={activity.alt}
          title={activity.title}
          className="w-72 h-72 flex-none"
        />
      ))}
    </div>
  );
};

export default ActivitiesSection;
