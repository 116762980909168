import React from 'react';
import Review from './review';

const ReviewsSection = () => {
  const reviews = [
    {
      image: './reviewers/reviewer-1.webp',
      name: 'Brenden Shue',
      designation: 'Strategic Partnerships',
      company: 'Kukun',
      headline: 'It is rare to find someone like Saif ',
      review:
        "I have worked with Saif for just under 2 years, and I can say he is an absolute pleasure to work with and a true asset to any team. It is rare to find someone with the breadth and depth of skills that Saif has honed as a product manager, designer, and leader. Saif's work regularly exceeded my expectations, and I became spoiled by his quick responsiveness in both communication and action. There are many notable times when Saif took his personal time to develop internal tools, design pitches, or even learn new code to help other departments and colleagues succeed.",
    },
    {
      image: './reviewers/reviewer-5.webp',
      name: 'Christopher Mathew',
      designation: 'Client',
      company: 'Devigners',
      headline: 'Willing to go the extra mile',
      review:
        'Saif is smart, hard-working, a great listener, and can handle any type of website that requires multiple technologies. Super Responsive, Collaboration, Communication, thinking out of the box, being willing to go the extra mile, and Punctuality are a few other things that stand out in him more than others. Thanks for delivering this project with all my requirements. Best.',
    },
    {
      image: './reviewers/reviewer-3.webp',
      name: 'Aiman Tahir',
      designation: 'Data Engineer',
      company: 'Devigners',
      headline: 'Knows all the rules and principles of designing',
      review:
        'Saif is an amazing person in every aspect of life. It was very inspiring to work with someone that shared his passion for design and user experience. He knows all the rules and principles of designing and impresses with his creative designs. His visual design skills, coupled with his user experience design abilities, make him a very well-rounded interaction designer. Saif has the ability to mix different colours and make a very smooth design. His knowledge and experience goes far beyond his primary interest of user experience. He is always finding new techniques to follow in the world of designing.',
    },

    {
      image: './reviewers/reviewer-2.webp',
      name: 'Nusrate Ibrahim',
      designation: 'Head of Research',
      company: 'ESGLibrary',
      headline: 'Love the entire process with him',
      review:
        'Saif understood my brief, customer base and goals better than any other designer out there. He explained his design process and kept me informed every step of the way. I love how the entire process was managed and the final work delivered exceeded my expectations.',
    },
    {
      image: './reviewers/reviewer-4.webp',
      name: 'Hissaan Shah',
      designation: 'Azure Data Scientist',
      company: 'Germany',
      headline: 'Knowledgeable, driven, and enthusiastic',
      review:
        'I have worked with Saif Ullah on many freelancing projects where he performed his UI UX tasks for the clients. Saif is a knowledgeable, driven, and enthusiastic designer. User experience expert in search of excellence in his designs. Apart from being a good product designer, he was really friendly to everyone around him. A must recommend to anyone needing a creative solution advisor and product designer. Thank you.',
    },
    {
      image: './reviewers/reviewer-6.webp',
      name: 'Kishen Patel',
      designation: 'Client',
      company: 'Fiverr',
      headline: 'The final product was looking way more clean',
      review:
        'I had a great time working with Saif. He was patient and understanding. He understood the design task and delivered beautifully, the final product was looking way more clean then before. I look forward to working with him again!',
    },
  ];

  return (
    <div className="flex flex-col w-full pt-16 pb-12 border-b">
      <div className="flex flex-col gap-4">
        <div className="text-sm text-brand font-medium">Reviews</div>
        <div className="text-3xl font-medium">Words from my colleagues </div>
      </div>
      <div className="flex flex-col md:flex-row gap-4 xl:gap-8 pt-8">
        <div className="flex flex-col gap-4 xl:gap-8 flex-1">
          <Review
            image={reviews[0].image}
            name={reviews[0].name}
            designation={reviews[0].designation}
            company={reviews[0].company}
            headline={reviews[0].headline}
            review={reviews[0].review}
            className="w-full"
          />
          <Review
            image={reviews[1].image}
            name={reviews[1].name}
            designation={reviews[1].designation}
            company={reviews[1].company}
            headline={reviews[1].headline}
            review={reviews[1].review}
            className="w-full"
          />
        </div>
        <div className="flex flex-col gap-4 xl:gap-8 pt-4 flex-1">
          <Review
            image={reviews[2].image}
            name={reviews[2].name}
            designation={reviews[2].designation}
            company={reviews[2].company}
            headline={reviews[2].headline}
            review={reviews[2].review}
            className="w-full"
          />
          <Review
            image={reviews[3].image}
            name={reviews[3].name}
            designation={reviews[3].designation}
            company={reviews[3].company}
            headline={reviews[3].headline}
            review={reviews[3].review}
            className="w-full"
          />
        </div>
        <div className="hidden lg:flex md:flex-col gap-4 xl:gap-8 flex-1">
          <Review
            image={reviews[4].image}
            name={reviews[4].name}
            designation={reviews[4].designation}
            company={reviews[4].company}
            headline={reviews[4].headline}
            review={reviews[4].review}
            className="w-full"
          />
          <Review
            image={reviews[5].image}
            name={reviews[5].name}
            designation={reviews[5].designation}
            company={reviews[5].company}
            headline={reviews[5].headline}
            review={reviews[5].review}
            className="w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default ReviewsSection;
