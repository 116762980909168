import { Award, Camera, File, Heart } from 'lucide-react';
import CollapsibleSection from '../../collapsibleSection';
import ActivitiesSection from './activitiesSection';
import AwardsSection from './awardsSection';
import ResumeSection from './resumeSection';
import SideInitiativesSection from './sideInitiativesSection';

const SectionsSection = () => {
  const sections = [
    {
      icon: (
        <Camera
          height={22}
          width={22}
          strokeWidth="2"
          className="text-blackSecondary opacity-80"
        />
      ),
      title: 'Activities',
      collapsible: false,
      children: <ActivitiesSection />,
    },
    {
      icon: (
        <File
          height={22}
          width={22}
          strokeWidth="2"
          className="text-blackSecondary opacity-80"
        />
      ),
      title: 'Resume',
      collapsible: true,
      children: <ResumeSection />,
    },
    {
      icon: (
        <Award
          height={22}
          width={22}
          strokeWidth="2"
          className="text-blackSecondary opacity-80"
        />
      ),
      title: 'Awards',
      collapsible: true,
      children: <AwardsSection />,
    },
    {
      icon: (
        <Heart
          height={22}
          width={22}
          strokeWidth="2"
          className="text-blackSecondary opacity-80"
        />
      ),
      title: 'Side Initiatives',
      collapsible: true,
      children: <SideInitiativesSection />,
    },
  ];

  return (
    <div className="w-full pb-16 border-b">
      <div className="flex flex-col gap-4">
        {sections.map((section, index) => (
          <CollapsibleSection
            icon={section.icon}
            title={section.title}
            collapsible={section.collapsible}
            className="w-full"
          >
            {section.children}
          </CollapsibleSection>
        ))}
      </div>
    </div>
  );
};

export default SectionsSection;
