import React from 'react';

interface HeaderItemProps {
  label: string;
  href: string;
}

const HeaderItem = ({ label, href }: HeaderItemProps) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className="font-medium text-blackSecondary hover:text-brand brand-transition"
    >
      {label}
    </a>
  );
};

export default HeaderItem;
