import React from 'react';

interface TimelineItemProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  subtitle?: string;
  dateFrom?: string;
  dateTo?: string;
  liItems?: string[];
}

const TimelineItem = ({
  title,
  subtitle,
  dateFrom,
  dateTo,
  liItems,
  className,
}: TimelineItemProps) => {
  return (
    <div className={'flex flex-col gap-2 pt-4 ' + className}>
      <div className="flex flex-col gap-0">
        <div className="flex justify-between items-center">
          <div className="text-brand font-medium text-lg">{title}</div>
          <div className="text-blackSecondary opacity-75">
            {dateFrom} {dateTo && ' - ' + dateTo}
          </div>
        </div>
        <div className="text-base text-brand font-light">{subtitle}</div>
      </div>
      {liItems && (
        <div>
          <ul
            className={`flex flex-col gap-1 ${
              liItems.length > 1 ? 'list-disc pl-6' : ''
            }`}
          >
            {liItems.map((liItem, index) => (
              <li
                key={index}
                className="text-sm text-blackSecondary opacity-80"
              >
                {liItem}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TimelineItem;
