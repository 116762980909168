import React from 'react';

interface ButtonProps extends React.HTMLProps<HTMLDivElement> {
  label: string;
  isScroll?: boolean;
}

const Button = ({ label, isScroll = true, className }: ButtonProps) => {
  const handleScroll = () => {
    const element = document.getElementById('profileSection');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <button
      className={`flex w-fit text-base py-5 px-16 bg-brand rounded-lg text-white items-center justify-center ${className}`}
      onClick={
        isScroll
          ? handleScroll
          : () => window.open('mailto:saifbinkhaki@gmail.com')
      }
    >
      {label}
    </button>
  );
};

export default Button;
