import React, { useState } from 'react';
import { Minus, Plus } from 'lucide-react';

interface CollapsibleSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  icon: React.ReactNode;
  title: string;
  collapsible: boolean;
}

const CollapsibleSection = ({
  icon,
  title,
  collapsible,
  className,
  children,
}: CollapsibleSectionProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <div
      className={`rounded-lg bg-whiteSecondary flex flex-col gap-2 p-4 px-0 ${className}`}
      onClick={() => (collapsible ? setIsCollapsed(!isCollapsed) : null)}
    >
      <div className="w-full flex justify-between">
        <div className="flex gap-3 pl-4 items-center justify-center">
          {icon}
          <div className="text-lg text-blackSecondary">{title}</div>
        </div>
        {collapsible && isCollapsed && (
          <Plus
            width={24}
            height={24}
            strokeWidth="2"
            className="text-blackSecondary opacity-50 mr-4"
          />
        )}
        {collapsible && !isCollapsed && (
          <Minus
            width={24}
            height={24}
            strokeWidth="2"
            className="text-blackSecondary opacity-50 mr-4"
          />
        )}
      </div>
      {!collapsible && <div>{children}</div>}
      <div className={`${!isCollapsed ? 'reveal' : 'hidden'}`}>{children}</div>
    </div>
  );
};

export default CollapsibleSection;
