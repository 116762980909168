import React from 'react';
import Button from '../../button';

const HeroSection = () => {
  return (
    <div className="mt-8 md:mt-32 flex gap-16 items-center">
      <div className="flex flex-col gap-6 pb-12">
        <div className="flex flex-col gap-4">
          <div className="flex items-center bg-brandSecondary gap-2 p-1 px-2 rounded-md w-fit">
            <div className="h-[6px] w-[6px] rounded-full bg-brand"></div>
            <div className="text-sm text-brand font-medium">
              Open for side projects
            </div>
          </div>
          <div className="font-medium text-3xl md:text-4xl leading-normal">
            Hi, I'm Saifeeee
            <br />
            Product Designer & UI UX Developer in Berlin.
          </div>
          <img
            src="./cover-photo-test.webp"
            alt="Cover"
            className="w-full h-64 object-cover rounded-2xl block md:hidden"
          />
        </div>
        <div className="text-base text-blackSecondary">
          Product Designer based in Germany, specializing in User interfaces and
          User experiences. Since 2018, I've enjoyed working in agencies,
          companies, startups, and real estate, leading projects used by
          thousands of people. Got opportunity to be working in four different
          countries, i.e. Pakistan, UAE, USA, Germany.
        </div>
        <Button label="About me" />
      </div>
      <img
        src="./cover-photo.webp"
        alt="Cover"
        className="w-80 rounded-3xl hidden md:block"
      />
    </div>
  );
};

export default HeroSection;
