import React from 'react';
import TimelineItem from '../timelineItem';

const ResumeSection = () => {
  return (
    <div className="flex flex-col gap-2 px-4">
      <TimelineItem
        title="Devigners LLC"
        subtitle="Product Designer"
        dateFrom="2023"
        dateTo="2024"
        liItems={[
          'Designed user-centered interface designs (UCD) with designing teams, conducting user experience research, usability tests, focus groups, and A/B testing for R&D.',
          'Worked with developers and product managers, building wireframes, mockups and prototypes for web and mobile applications - optimizing client retention by 32%.',
          'Enhanced customer experience with accessibility principles (WCAG 2.2) and animations.',
          'Implemented and maintained design systems and leveraged atomic design methodology (ADM) by working between Figma and HTML/CSS.',
        ]}
        className="pb-6 border-b"
      />
      <TimelineItem
        title="Diamond Developers"
        subtitle="UI UX Designer"
        dateFrom="2022"
        dateTo="2023"
        liItems={[
          'Crafted high-fidelity prototypes using Figma, focusing on user personas and empathy maps for THE SUSTAINABLE CITY project, increasing sales by 26%.',
          'Developed comprehensive branding guidelines, conducted content audits, and collaborated closely with developers to ensure design consistency and high-quality user experiences.',
          'Designed eye-catching icons for their design system that enhanced interaction designs.',
        ]}
        className="pb-6 border-b"
      />
      <TimelineItem
        title="Watersprint Ltd."
        subtitle="UI UX Designer"
        dateFrom="2021"
        dateTo="2022"
        liItems={[
          'Developed original website design concepts, wireframes, storyboards, user flows, and site maps to solve complex interaction challenges for multiple websites across the company.',
          'Collaborated with the UI/UX team on user experience research and experience mapping.',
        ]}
        className="pb-2"
      />
    </div>
  );
};

export default ResumeSection;
