import React from 'react';

const CompanySection = () => {
  const images = [
    { image: './companies/Logo-1.svg', alt: 'TSC Logo' },
    { image: './companies/Logo-2.svg', alt: 'Hounter Logo' },
    { image: './companies/Logo-3.svg', alt: 'ESGLibrary Logo' },
    { image: './companies/Logo-4.svg', alt: 'Devigers Logo' },
    { image: './companies/Logo-5.svg', alt: 'Trapix Logo' },
    { image: './companies/Logo-6.svg', alt: 'Islam and Sustainability Logo' },
    { image: './companies/Logo-7.svg', alt: 'TSC Yiti Logo' },
    { image: './companies/Logo-8.svg', alt: 'Green Care Logo' },
    { image: './companies/Logo-9.svg', alt: 'Mercury Logo' },
  ];

  return (
    <div className="relative w-full pt-8 md:pt-28 pb-16 border-b overflow-hidden">
      <div className="absolute inset-y-0 z-10 left-0 w-24 bg-gradient-to-r from-white to-transparent"></div>
      <div className="absolute inset-y-0 z-10 right-0 w-24 bg-gradient-to-l from-white to-transparent"></div>
      <div className="flex gap-8 md:gap-12 filter grayscale z-0 animate-mobscroll md:animate-scroll">
        {images.concat(images).map((image, index) => (
          <img key={index} src={image.image} alt={image.alt} className="h-10" />
        ))}
      </div>
    </div>
  );
};

export default CompanySection;
